<template>
    <div class="chart-page--container flex-item">
        <div :id="key" class="chart-page"></div>

        <canvas-data v-if="canvasData.show" :param="canvasData"></canvas-data>
    </div>
</template>


<script>
    import graphMixin from '@/util/graphMixin'
    export default {
        props: ['corp', 'visitId'],
        mixins: [graphMixin],
        data() {
            return {
                key: 'assistDose',

                firstKey: 'comName',
                firstValue: '通用名',

                secondKey: 'dose',
                secondQtyValue: '测试',
                secondQtyKey: 'qty',
                secondApi: 'instruction_index/drug-dosage-positive',

                thirdKey: 'comName',
                thirdQtyKey: 'sale_amount',
                thirdApi: 'instruction_index/drug-dosage-reverse',

            }
        },
    }
</script>